import { cloneDeep } from "lodash";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";

export const SET_TARGETS_PLAN_SUMMARY_API_REFRESH = 'SET_TARGETS_PLAN_SUMMARY_API_REFRESH';
export const SET_TARGETS_PLAN_SUMMARY_DATA = 'SET_TARGETS_PLAN_SUMMARY_DATA';
export const SET_TARGETS_PLAN_SUMMARY_DATA_CLONE ='SET_TARGETS_PLAN_SUMMARY_DATA_CLONE';
export const SET_TARGETS_SUMMARY_DATA_TABLE_CURRENT_PAGE = 'SET_TARGETS_SUMMARY_DATA_TABLE_CURRENT_PAGE';
export const SET_TARGETS_SUMMARY_DATA_TABLE_PAGE_SIZE = 'SET_TARGETS_SUMMARY_DATA_TABLE_PAGE_SIZE';
export const SET_TARGETS_SUMMARY_DATA_TOTAL_RECORD_COUNT ='SET_TARGETS_SUMMARY_DATA_TOTAL_RECORD_COUNT';
export const SET_TARGETS_SUMMARY_DATA_TABLE_DEFAULT_FILTERS ='SET_TARGETS_SUMMARY_DATA_TABLE_DEFAULT_FILTERS'
export const SET_TARGETS_SUMMARY_DROPDOWN_SELECTED_FILTERS ='SET_TARGETS_SUMMARY_DROPDOWN_SELECTED_FILTERS'
export const SET_TARGETS_SUMMARY_TABLE_DATA_LOADING = 'SET_TARGETS_SUMMARY_TABLE_DATA_LOADING';

export const setTargetsSummaryDefaultFilters = (filters = {}) => ({
    type:SET_TARGETS_SUMMARY_DATA_TABLE_DEFAULT_FILTERS,
    payload:cloneDeep(filters)
})

export const setTargetsSummaryDropdownAppliedFilters = (filters = {}) => ({
    type:SET_TARGETS_SUMMARY_DROPDOWN_SELECTED_FILTERS,
    payload:cloneDeep(filters)
})

export const setTargetsSummaryApiTrigger = (isRefreshedData = false) => ({
    type:SET_TARGETS_PLAN_SUMMARY_API_REFRESH, payload:isRefreshedData
})

export const setTargetsSummaryData = (data = [], isDataLoading = false) => ({
    type:SET_TARGETS_PLAN_SUMMARY_DATA,
    payload:{data:cloneDeep(data), isLoading:isDataLoading}
})

export const setTargetsSummaryDataClone = (data = [], isDataLoading = false) => ({
    type:SET_TARGETS_PLAN_SUMMARY_DATA_CLONE,
    payload:{displayData:cloneDeep(data), isLoading:isDataLoading}
})

export const setTargetsSummaryDataTableCurrentPage = (currentPage = 1) => ({
    type: SET_TARGETS_SUMMARY_DATA_TABLE_CURRENT_PAGE,
    payload:currentPage
})

export const setTargetsSummaryDataTablePageSize = (pageSize= DEFAULT_TABLE_PAGE_SIZE) => ({
    type: SET_TARGETS_SUMMARY_DATA_TABLE_PAGE_SIZE,
    payload:pageSize
})

export const setTargetsSummaryDataTotalRecordCount = (totalRecordCount = 0) => ({
    type:SET_TARGETS_SUMMARY_DATA_TOTAL_RECORD_COUNT,
    payload:totalRecordCount
})

export const setTargetsSummaryTableDataLoading = (bool) => ({
    type:SET_TARGETS_SUMMARY_TABLE_DATA_LOADING,
    payload:bool
})