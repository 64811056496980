import React, { useEffect, Suspense, lazy } from 'react';
import { Route, Routes } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ProtectedRoute from "../ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllAmazonCategories, getAllCategories,
    getAllCountries, getAllCurrencies, getAllDealStatus, getAllDealTypes,
    getAllEvent, getAllPeakEventFlag, getUserDetails
} from "../services/base";
import { isNull, isUndefined } from "lodash";
import AppErrorBoundary from "../components/AppErrorBoundary";
import { setGlobalDataLoadCompleted } from "../store/actions/base-actions";
import HomeLayout from "./../container/HomeLayout.js";

const MonthlyPlan = lazy(() => import("./../container/monthly-plan/index.js"));
const WeeklyPlan = lazy(() => import("./../container/weekly-plan/index.js"));
const FinancialSummary = lazy(() => import("./../container/summary/index.js"));
const DealPlan = lazy(() => import("./../container/deal-plan/index.js"));
const DealsSummary = lazy(() => import("./../container/deal-plan-summary/index.js"));
const TargetsContainer = lazy(() => import("./../container/targets/index.js"));
// const TargetsSummary = lazy(() => import("../container/targets-summary/index.js"));
const InvalidPage = lazy(() => import("./../container/404/index.js"));
const AccessControlScreen = lazy(() => import('./../container/AccessControlScreen.js'));
const PricesComponent = lazy(() => import("./../container/prices/index.js"));
const Admin = lazy(() => import("./../container/admin/index.js"));

const RoutesContainer = (props) => {
    const { emailFromSSO, hasAccessToken } = props;
    const dispatch = useDispatch();

    const { globalDataLoadCompleted } = useSelector((state) => state.base);
    const {
        accessScreen,
        isLoggedInAsPerAccess
    } = useSelector((state) => state.accessControl);

    useEffect(() => {
        const retrieveUserDetails = async () => {
            if (!isLoggedInAsPerAccess && (!isNull(emailFromSSO) && !isUndefined(emailFromSSO) && emailFromSSO !== '')) {
                dispatch(getUserDetails())
            }
        }
        retrieveUserDetails();
    }, [emailFromSSO, isLoggedInAsPerAccess]);


    const handleGlobalDataSets = async () => {
        await dispatch(setGlobalDataLoadCompleted(true))
        await dispatch(getAllDealTypes())
        await dispatch(getAllDealStatus())
        await dispatch(getAllAmazonCategories())
        await dispatch(getAllPeakEventFlag())
        await dispatch(getAllEvent())
        await dispatch(getAllCountries())
        await dispatch(getAllCategories())
        await dispatch(getAllCurrencies())
        return true
    }
    useEffect(() => {
        if (hasAccessToken){
            if(globalDataLoadCompleted === false) {
                handleGlobalDataSets();
            }
        }
    }, [hasAccessToken, globalDataLoadCompleted, emailFromSSO]);

    return (
        <Routes>
            <Route errorElement={<AppErrorBoundary />} path='/' element={<HomeLayout />}>
                <Route
                    path='login' errorElement={<AppErrorBoundary />}
                    element={
                        <AccessControlScreen />
                    } index={(isLoggedInAsPerAccess === false && accessScreen === '') ? true : false}
                />
                <Route
                    path="/monthly-planner" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    index={isLoggedInAsPerAccess && accessScreen === 'Monthly' ? true : false} 
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} ><MonthlyPlan /></Suspense>
                        } isAuthenticated={isLoggedInAsPerAccess}
                    />
                    }
                />
                <Route
                    path="monthly-planner/summary" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} children={
                                <FinancialSummary
                                    pageName='MonthlyPlanFinancialSummary' dataContainer={'monthlyPlan'}
                                />}
                            />
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                />
                <Route
                    path="/weekly-planner" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    index={isLoggedInAsPerAccess && accessScreen === 'Weekly' ? true : false} 
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} children={<WeeklyPlan />} />
                        } isAuthenticated={isLoggedInAsPerAccess}
                    />
                    }
                />
                <Route
                    path="weekly-planner/summary" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} children={
                                <FinancialSummary
                                    pageName='WeeklyPlanFinancialSummary' dataContainer={'weeklyPlan'}
                                />}
                            />
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                />
                <Route
                    path="deal-planner" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} children={<DealPlan />} />
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                />
                <Route
                    path="deal-planner/summary" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} ><DealsSummary /></Suspense>
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                />
                <Route
                    path="target-planner" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} ><TargetsContainer /></Suspense>
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                />
                {/* <Route
                    path="target-planner/summary" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} ><TargetsSummary /></Suspense>
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                /> */}
                
                <Route
                    path="prices" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <ProtectedRoute children={
                            <Suspense fallback={<LoadingOutlined />} children={<PricesComponent />} />
                        } isAuthenticated={isLoggedInAsPerAccess}
                        />
                    }
                />
                <Route
                    path="admin-panel" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                        <Suspense fallback={<LoadingOutlined />} children={<Admin />} />
                    }
                />
                <Route path="*" errorElement={<AppErrorBoundary />} loader={<Spin tip="Loading" size="large" />}
                    element={
                    <Suspense fallback={<LoadingOutlined />} ><InvalidPage /></Suspense>
                    }
                />
            </Route>
        </Routes>
    )
}
export default RoutesContainer;
