import { cloneDeep } from "lodash";

export const SET_APP_APPLICABLE_YEARS = 'SET_APP_APPLICABLE_YEARS';
export const SET_COUNTRY_MAPPING = 'SET_COUNTRY_MAPPING';
export const SET_COUNTRY_CATEGORY_MAPPING = 'SET_COUNTRY_CATEGORY_MAPPING';
export const SET_CATEGORY_LIST_MAPPING = 'SET_CATEGORY_LIST_MAPPING';
export const SET_DEAL_TYPE_LIST_MAPPING = 'SET_DEAL_TYPE_LIST_MAPPING';
export const SET_DEAL_STATUS_LIST_MAPPING = 'SET_DEAL_STATUS_LIST_MAPPING';
export const SET_PEAK_EVENT_FLAG_LIST_MAPPING = 'SET_PEAK_EVENT_FLAG_LIST_MAPPING';
export const SET_EVENT_LIST_MAPPING = 'SET_EVENT_LIST_MAPPING';
export const SET_AMAZON_CATEGORY_LIST_MAPPING = 'SET_AMAZON_CATEGORY_LIST_MAPPING';
export const SET_METRIC_LIST_MAPPING = 'SET_METRIC_LIST_MAPPING';
export const SET_IS_EDITABLE_METRIC_LIST_MAPPING = 'SET_IS_EDITABLE_METRIC_LIST_MAPPING';
export const SET_MONTHS_LIST = 'SET_MONTHS_LIST';
export const SET_WEEKS_LIST = 'SET_WEEKS_LIST';
export const SET_IS_DATA_MODIFIED = 'SET_IS_DATA_MODIFIED'
export const SET_IS_DATA_LOADING = 'SET_IS_DATA_LOADING'
export const SET_FILTER_LIST = 'SET_FILTER_LIST';
export const SET_ERROR = 'SET_ERROR';
export const SET_OK_BUTTON_LOADING_ICON = 'SET_OK_BUTTON_LOADING_ICON'
export const SET_EVENTS_LIST_FOR_CURRENT_YEAR = 'SET_EVENTS_LIST_FOR_CURRENT_YEAR';
export const SET_UPLOADABLE_TARGET_EVENTS_LIST = 'SET_UPLOADABLE_TARGET_EVENTS_LIST';
export const SET_GLOBAL_DATA_LOAD_COMPLETED = 'SET_GLOBAL_DATA_LOAD_COMPLETED'
export const SET_MASTER_EVENT_LIST_MAPPING = 'SET_MASTER_EVENT_LIST_MAPPING';
export const SET_CURRENCY_UNIT = 'SET_CURRENCY_UNIT';
export const SET_CURRENCIES_LIST_MAPPING = 'SET_CURRENCIES_LIST_MAPPING';
export const SET_CURRENCY = 'SET_CURRENCY';


export const setCurrentYearEventsList = (eventList = []) => ({
    type: SET_EVENTS_LIST_FOR_CURRENT_YEAR,
    payload: eventList
})

export const setCountryMapping = (countryList) => ({
    type:SET_COUNTRY_MAPPING,
    payload:cloneDeep(countryList)
})

export const setCategoryMappingList = (data) => ({
    type:SET_CATEGORY_LIST_MAPPING,
    payload:cloneDeep(data)  
})

export const setDealTypeMappingList = (dealTypeList) => ({
    type:SET_DEAL_TYPE_LIST_MAPPING,
    payload:cloneDeep(dealTypeList)  
})

export const setDealStatusMappingList = (dealStatusList) => ({
    type:SET_DEAL_STATUS_LIST_MAPPING,
    payload:cloneDeep(dealStatusList)  
})

export const setPeakEventFlagMappingList = (peakEventFlagList) => ({
    type:SET_PEAK_EVENT_FLAG_LIST_MAPPING,
    payload:cloneDeep(peakEventFlagList)  
})

export const setEventMappingList = (eventlist) => ({
    type:SET_EVENT_LIST_MAPPING,
    payload:cloneDeep(eventlist)  
})

export const setAmazonCategoryMappingList = (amazoncategorylist) => ({
    type:SET_AMAZON_CATEGORY_LIST_MAPPING,
    payload:cloneDeep(amazoncategorylist)  
})

export const setMetricMappingList = (data) => ({
    type:SET_METRIC_LIST_MAPPING,
    payload:cloneDeep(data)
})

export const setIsEditableMetricMappingList = (data) => ({
    type:SET_IS_EDITABLE_METRIC_LIST_MAPPING,
    payload:cloneDeep(data)
})

export const setIsDataModified = (boolValue) => ({
    type:SET_IS_DATA_MODIFIED, payload:boolValue
})

export const setFilterList = (filterList) => ({
    type:SET_FILTER_LIST, payload:filterList
})
    
export const setOkButtonLoading = (boolValue) => ({
    type:SET_OK_BUTTON_LOADING_ICON,
    payload: boolValue
})

export const setDataLoadingState = (boolValue) => ({
    type:SET_IS_DATA_LOADING,
    payload: boolValue
})

export const setGlobalDataLoadCompleted = (boolValue) => ({
    type:SET_GLOBAL_DATA_LOAD_COMPLETED,
    payload:boolValue
})

export const setUploadableTargetEvents = (eventList = []) => ({
    type: SET_UPLOADABLE_TARGET_EVENTS_LIST,
    payload:eventList
})

export const setMasterEventMappingList = (eventslist) => ({
    type:SET_MASTER_EVENT_LIST_MAPPING,
    payload:cloneDeep(eventslist)  
})


export const setCurrencyUnit = (unit = 1) => ({
    type: SET_CURRENCY_UNIT,
    payload: unit
})


export const setCurrencyListMapping = (currencyList = []) => ({
    type: SET_CURRENCIES_LIST_MAPPING,
    payload: currencyList
})


export const setCurrency = (currency = 'euro') => ({
    type: SET_CURRENCY,
    payload:  currency
})