import React from 'react'
import { Col, Flex, Layout, Row, Space, Typography } from "antd"
import image from "./../../assets/Images/philips-white-logo.svg";
import { ButtonComponent } from "../../components/Button";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequests } from "../../config/msalConfig";
import { retrieveProfileData } from "../../services/msal-service";
import moment from "moment";
import { LoginOutlined } from '@ant-design/icons';

const { Title } = Typography;

const UnauthorizedUser = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const signInToAzure = () => {
        instance.loginRedirect(loginRequests).then(() => {
            return retrieveProfileData(instance, accounts)
        })
            .catch((e) => {
                console.log(e);
            });
    }

    const requestToolAccess = () => {
        return;
    }
    return (
        <Layout className="layout">
            <Header className='app-theme-gradient'
                style={{
                    display: 'flex',
                    minHeight:'10dvh',
                    paddingLeft: 0,
                    paddingRight: 0,
                    alignItems: 'center'
                }}
            >
                <Row align='middle' justify='space-between' style={{ width: '100%', padding: '8px 16px'}}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent:'flex-start' }}>
                    <img src={image} alt="Philips logo" style={{ width: 'auto', height: '3.5em', margin: 0 }} />
                    <Title level={2}
                        className='theme-heading-font'
                        style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            margin: '0 0 0 8px',
                            fontSize: '1.8em',
                        }} >Planning Tool</Title>
                </Col>
                </Row>
            </Header>
            <Content
                style={{
                    padding: '0 20px',
                    minHeight: `calc(90dvh - 78px)`,
                    display: 'flex',
                    alignItems:'center',
                    justifyContent: 'center'
                }}
            >
                <Flex vertical={true} wrap='wrap' gap='large' align='center' justify='center'>
                    <Title level={1}>Sales Planning Tool!!</Title>
                    {!isAuthenticated ? (
                        <>
                            <ButtonComponent tooltipText='Sign in to Azure to access the application'
                                onClick={signInToAzure} loading={null} size='large' style={{ background: '#0066a1' }}
                                themeType="nav" title="Sign In" icon={<LoginOutlined />}/>
                        </>
                    ) : (
                        <>
                            <Title level={4}>Seem's like you dont have access to the tool </Title>
                            <ButtonComponent tooltipText='Request Application Access'
                                onClick={requestToolAccess} loading={null} size='large' style={{ background: '#0066a1' }}
                                themeType="nav" title="Request Access" />
                        </>
                    )}
                </Flex>
            </Content>
            <Footer className='app-theme-gradient'
                style={{
                    textAlign: 'right',
                    minHeight: '62px'
                }}
            >
                <Title level={4} style={{color:'#fff', margin:0}}>Philips © {moment().year()}</Title>
            </Footer>
        </Layout>
    )
}

export default UnauthorizedUser;
