import React, { useEffect, useState } from 'react'
import { Col, Divider, Drawer, Row, Space, Typography } from "antd"
import NavigationLink from "./NavigationLink"
import { ButtonComponent } from "./Button"
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import image from "./../assets/Images/philips-white-logo.svg";
import { useDispatch, useSelector } from "react-redux"
import { setUserAccessScreens } from "../store/actions/access-control-action"
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { logOutUser } from "../helper/validateLoggedInCountryAccess"
import { isEmpty, isNull, isUndefined, size } from "lodash"
import { useViewPort } from "../hooks/useViewPort"

const { Title } = Typography;


export const HeaderNavigationPanel = () => {
    const { accessScreen, isLoggedInAsPerAccess } = useSelector((state) => state.accessControl);

    const {
        displayDetails, userDetails
    } = useSelector((state) => state.users);

    const { UserName, CountryCode } = displayDetails;
    const { hasAdminAccess } = userDetails;
    const { screenWidth } = useViewPort()
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const dispatch = useDispatch();

    const [showCollapsableDrawerNavigation, setShowCollapsableDrawerNavigation] = useState(false);
    useEffect(() => {
        const isScreenWidthObjectNull = isNull(screenWidth) && isUndefined(screenWidth)
        const isAccessScreenArrayEmpty = Array.isArray(accessScreen) && isEmpty(accessScreen)
        if (isLoggedInAsPerAccess && (
            (isScreenWidthObjectNull === false && isAccessScreenArrayEmpty === false ) && (screenWidth < 1400 || size(accessScreen) >=3)
        )) {
            if (screenWidth > 1980){
                setShowCollapsableDrawerNavigation(false)
            }else {
                setShowCollapsableDrawerNavigation(true)
            }
        } else {
            setShowCollapsableDrawerNavigation(false)
        }
    }, [screenWidth, isLoggedInAsPerAccess])


    const onClickApplicationLogout = async () => {
        await logOutUser()
        dispatch(setUserAccessScreens(''))
        return instance.logoutRedirect({ postLogoutRedirectUri: "/planning-app/login" })
    }
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <Row align='middle' justify='space-between' style={{ width: '100%', padding: '8px 16px' }}>
            <Col xs={20} md={12} lg={8} xl={5} xxl={4} style={{ display: 'flex', alignItems: 'center', justifyContent:'flex-start' }}>
                <img src={image} alt="Philips logo" style={{ width: 'auto', height: '3.5em', margin: 0 }} />
                <Title level={2}
                    className='theme-heading-font'
                    style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        margin: '0 0 0 8px',
                        fontSize: '1.8em',
                    }} >Planning Tool</Title>
            </Col>
            <Col xs={4} md={12} lg={16} xl={19} xxl={20} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(!isNull(screenWidth) && !isUndefined(screenWidth) &&
                    (screenWidth > 768 && screenWidth < 1200)
                ) && (
                        <Title level={4} className='theme-regular-font'
                            style={{
                                color: 'white', fontWeight: 'bold', display: 'inline-block',
                                fontSize: '1.2em', margin: 'auto 0', padding: '0 10px 0 0'
                            }}
                        >{UserName + ' - ' + CountryCode}
                        </Title>
                    )
                }
                
                {( showCollapsableDrawerNavigation && ((!(0 < screenWidth < 920) && (1400 < screenWidth < 1800)) || (screenWidth > 1800 && size(accessScreen) >2))
                ) && (
                        <Title level={4} className='theme-regular-font'
                            style={{
                                color: 'white', fontWeight: 'bold', display: 'inline-block',
                                fontSize: '1.2em', margin: 'auto 0', padding: '0 10px 0 0'
                            }}
                        >{UserName}{isLoggedInAsPerAccess? ' - ': ''}{CountryCode}
                        </Title>
                    )
                }
                {showCollapsableDrawerNavigation === false && (
                    <Space wrap={true}>
                        {accessScreen.includes('Monthly') && (
                            <>
                                <NavigationLink text="Monthly Plan" redirectTo={"/monthly-planner"} />
                                <NavigationLink text="Financial Summary" redirectTo={"/monthly-planner/summary"} />
                            </>
                        )}

                        {accessScreen.includes('Weekly') && (
                            <>
                                <NavigationLink text="Weekly Plan" redirectTo={"/weekly-planner"} />
                                <NavigationLink text="Financial Summary" redirectTo={"/weekly-planner/summary"} />
                            </>
                        )}
                        {(accessScreen.includes('Deal')) && (
                            <>
                                <NavigationLink text="Deal Plan" redirectTo={"/deal-planner"} />
                                <NavigationLink text="Deals Summary" redirectTo={"/deal-planner/summary"} />
                            </>
                        )}
                        {accessScreen.includes('Target') && (
                            <>
                            <NavigationLink text="Targets" redirectTo="/target-planner" />
                            {/* {hasAdminAccess && (<NavigationLink text="Targets Summary" redirectTo={"/target-planner/summary"} />)} */}
                            </>
                        )}
                        {accessScreen.includes('Prices') && (
                            <>
                                <NavigationLink text="Prices" redirectTo="/prices" />
                            </>
                        )}
                        {(hasAdminAccess || accessScreen.includes('Admin')) ?
                            <>
                            <NavigationLink text="Admin Panel" redirectTo="/admin-panel" hasAdminAccess={hasAdminAccess} />
                        </> : null}
                    </Space>
                )}
                {showCollapsableDrawerNavigation && (
                    <>
                        <ButtonComponent
                            title=''
                            tooltipText='Navigation Links'
                            style={{ backgroundColor: 'transparent', marginRight: '10px' }}
                            onClickHandle={showDrawer}
                            icon={open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        />
                        <Drawer placement="right" onClose={onClose} open={open} width={355} zIndex={99999} header={{ display: 'none' }}
                            title={
                                <Title level={3} className='theme-regular-font'
                                    style={{
                                        color: '#0066a1', margin: 0,
                                        fontWeight: 'bold', display: 'inline-block', fontSize: '1.2em', padding: 0
                                    }}

                                >{UserName + ' - ' + CountryCode}
                                </Title>
                            }
                            styles={{header:{ 'padding': 8 }, body:{ padding: 8} }}
                            >
                            {accessScreen.includes('Monthly') && (
                                <>
                                    <NavigationLink isNav={false} text="Monthly Plan" redirectTo={"/monthly-planner"} />
                                    <Divider style={{ margin: 4 }} />
                                    <NavigationLink isNav={false} text="Financial Summary" redirectTo={"/monthly-planner/summary"} />
                                    <Divider style={{ margin: 4 }} />
                                </>
                            )}
                            {accessScreen.includes('Weekly') && (
                                <>
                                    <NavigationLink isNav={false} text="Weekly Plan" redirectTo={"/weekly-planner"} />
                                    <Divider style={{ margin: 4 }} />
                                    <NavigationLink isNav={false} text="Financial Summary" redirectTo={"/weekly-planner/summary"} />
                                    <Divider style={{ margin: 4 }} />
                                </>
                            )}
                            {(accessScreen.includes('Deal')) && (
                                <>
                                    <NavigationLink isNav={false} text="Deal Plan" redirectTo={"/deal-planner"} />
                                    <Divider style={{ margin: 4 }} />
                                    <NavigationLink isNav={false} text="Deals Summary" redirectTo={"/deal-planner/summary"} />
                                    <Divider style={{ margin: 4 }} />
                                </>
                            )}
                            {accessScreen.includes('Target') && (
                                <>
                                    <NavigationLink isNav={false} text="Targets" redirectTo="/target-planner" />
                                    <Divider style={{ margin: 4 }} />
                                    {/* {hasAdminAccess && (
                                        <>
                                        <NavigationLink isNav={false} text="Targets Summary" redirectTo={"/target-planner/summary"} />
                                        <Divider style={{ margin: 4 }} />
                                    </>)} */}
                                </>
                            )}
                            {accessScreen.includes('Prices') && (
                                <>
                                    <NavigationLink isNav={false} text="Prices" redirectTo="/prices" />
                                    <Divider style={{ margin: 4 }} />
                                </>
                            )}
                            {(hasAdminAccess || accessScreen.includes('Admin')) ?
                                <>
                                <NavigationLink isNav={false} text="Admin Panel" redirectTo="/admin-panel" hasAdminAccess={hasAdminAccess}/>
                                <Divider style={{ margin: 4 }} />
                            </>: null}
                        </Drawer>
                    </>
                )}
                
                {((!showCollapsableDrawerNavigation && (screenWidth > 2080 || (Array.isArray(accessScreen) && !isEmpty(accessScreen) && size(accessScreen) <=2)) )
                ) && (
                        <Title level={4} className='theme-regular-font'
                            style={{
                                color: 'white', fontWeight: 'bold', display: 'inline-block',
                                fontSize: '1.2em', margin: 'auto 0', padding: '0 10px 0 0'
                            }}
                        >{UserName}{isLoggedInAsPerAccess? ' - ': ''}{CountryCode}
                        </Title>
                    )
                }
                {(isAuthenticated) && (
                    <ButtonComponent
                        title=''
                        tooltipText='Logout of the application'
                        style={{ backgroundColor: 'transparent' }}
                        onClickHandle={onClickApplicationLogout}
                        icon={<LogoutOutlined rotate='-90' />}
                    />
                )}
            </Col>
        </Row>
    )
}