import { Space, Typography } from "antd"
import SelectDropdown from "./SelectDropdown"
import { useDispatch, useSelector } from "react-redux"
import { setCurrencyUnit, setCurrency } from "../store/actions/base-actions";
import moment from "moment";
import { isEmpty } from "lodash";
import { ButtonComponent } from "./Button";
import { setUserAccessScreens } from "../store/actions/access-control-action";
import { logOutUser } from "../helper/validateLoggedInCountryAccess";


export const FooterPanel = (props) => {
    const {isLoggedInAsPerAccess} = useSelector((state) => state.accessControl);
    const {
        currencyUnits,
        selectedCurrencyUnit,
        currencies,
        selectedCurrency,
    } = useSelector((state) => state.base);
    const {data: currenciesList } = currencies;
    const { userDetails, displayDetails } = useSelector((state) => state.users);
    const { hasAdminAccess, accessCountries } = userDetails;
    const { CountryCode } = displayDetails;
    const dispatch  = useDispatch();

    const handleCurrencyUnitChange =  (unit, selectedComponentName) => {
        return dispatch(setCurrencyUnit(unit))
    }
    const handleCurrencyChange =  (currency, selectedComponentName) => {
        return dispatch(setCurrency(currency))
    }
    return (
        <Space wrap={true} size={'large'} align='center'>
            {/* {(isLoggedInAsPerAccess && Array.isArray(currenciesList) && !isEmpty(currenciesList)) && (
                <SelectDropdown
                    componentName='AppCurrency'
                    placeholder='Currency '
                    filterOptions={currenciesList.map(item => ({key: item.CountryCode, label: item.DisplayName}))}
                    style={{
                        minWidth: '280px', maxWidth:'unset', textAlign:'center', color:'#000'
                    }}
                    placement='topLeft'
                    defaultValue={[selectedCurrency]}
                    onChange={handleCurrencyChange}
                    selectionType={null}
                    showSelectAllOption={false}
                />
            )}
            {(isLoggedInAsPerAccess && Array.isArray(currencyUnits) && !isEmpty(currencyUnits)) && (
                <SelectDropdown
                    componentName='AppCurrencyUnit'
                    placeholder='Currency Unit'
                    filterOptions={currencyUnits}
                    style={{
                        minWidth: '150px', maxWidth:'unset', textAlign:'left', color:'#000'
                    }}
                    placement='topLeft'
                    defaultValue={[selectedCurrencyUnit]}
                    onChange={handleCurrencyUnitChange}
                    selectionType={null}
                    showSelectAllOption={false}
                />)
            } */}
            {(CountryCode && (Array.isArray(accessCountries) && accessCountries.length > 0)) &&
                <ButtonComponent 
                    title='Switch Country'
                    tooltipText='Switch Country'
                    style={{
                        cursor: 'pointer',
                        boxShadow: '3px 2px 2px 2px grey',
                        backgroundColor: '#E2E3E4',
                        color: '#000',
                        margin:'0 auto'

                    }}
                    onClickHandle={async () => {
                        await logOutUser()
                        await dispatch(setUserAccessScreens(''))
                        return window.location.pathname = '/planning-app/login'
                    }}
                />
            }
            <Typography.Title level={4} style={{color:'#fff', margin:0}}>
                Philips © {moment().year()}
            </Typography.Title>
        </Space>
    )
}