import { cloneDeep, isEmpty, isNaN, uniqueId } from "lodash";
import moment from "moment";
import { infoModal, successModal } from "../components/MessageModal";
import { CURRENCY_UNITS_OBJECT } from "./constants";


export const toObjectPayload = (key, value) => {
    const obj = {}
    obj[key] = value
    return obj
};

export const createDataClone = (data) => {
    const dataCopy = cloneDeep(data)
    dataCopy.map(dataSet => {
        dataSet['isModified'] = false
        dataSet['isAdded'] = false
        dataSet['isDeleted'] = false
        dataSet.uid = uniqueId()
    })
    return dataCopy
};

export const filterObject = (filters, pageNames) => {
    return filters && Object.keys(filters).length > 0 ?
        Object.keys(filters).filter(key => pageNames.map((type) => type).includes(key))
            .reduce((obj, key) => {
                obj[key] = filters[key];
                return obj;
            }, {}) : {}
};

export const filterList = (filters, pageNames) => {
    const filtersList = {}
    pageNames.map(page => Object.keys(filters[page]).map(key => filtersList[key] = filters[page][key]))
    return filtersList
};

export const makePageOffsetLimit = (currentPage, pageSize) => {
    return {
        offset: currentPage === 1 ? 0 : (pageSize * (currentPage - 1)),
        limit: pageSize
    }
};

export const getRangeOfDates = (startRange, endRange, format = 'YYYY-MM-DD', unit = 'days', arr = [moment(startRange).format(format)]) => {
    if (typeof (startRange) !== 'string' || typeof (endRange) !== 'string') {
        throw Error('StartDate & EndDate must be string')
    }
    const startDate = moment(startRange, format).startOf(unit)
    const endDate = moment(endRange, format).startOf(unit)
    if (startDate.isAfter(endDate)) {
        throw new Error('Start Date must precede End Date')
    }

    const next = moment(startDate, format).add(1, unit).startOf(unit);
    if (next.isAfter(endDate)) {
        return arr
    };
    arr.push(next.format(format))
    return getRangeOfDates(moment(next).format(format), moment(endDate).format(format), format, unit, arr);
};

export const makeOutputResponse = (reponseHeaderTitle = 'Success', response = {}) => {
    let successMessage = '', errorMessage = '', error = [];
    if (typeof response === 'object') {
        const keysInResponse = Object.keys(response)
        keysInResponse.map(key => {
            if (key === 'success' && response[key] !== '') {
                successMessage += response[key]
            }
            if (key === 'errorMessage' && response[key] !== '') {
                errorMessage += response[key]
            }
            if (key === 'mainError' && response[key] !== ''){
                console.log(`Main Error: ${String(response[key])}`)
            }
            if (key === 'error' && Array.isArray(response[key]) && !isEmpty(response[key])) {
                error += Array(response[key]).join(", ")
            }
            return true;
        })
        if (successMessage !== '') {
            successModal(reponseHeaderTitle, successMessage)
            return true;
        }
        if (errorMessage !== '') {
            infoModal(reponseHeaderTitle, errorMessage)
            if (error != '') {
                if ('clipboard' in navigator) {
                    navigator.clipboard.writeText(error)
                }
            }
            return false;
        }

    };

};

export const stringifyValues = (inputData) => {
    if (typeof inputData === 'string') {
        return inputData
    }
    if (typeof inputData === 'number') {
        return inputData.toString()
    } else if (Array.isArray(inputData)) {
        return inputData.map(ele => ele.toString())
    } else if (typeof (inputData) === 'object') {
        const result = {};
        for (let key in inputData) {
            result[key] = stringifyValues(inputData[key])
        }
        return result
        // }
    } else {
        return inputData.toString()
    }
};


const changeObjValue = (
    obj, revertToLastState, newCurr, lastCurr,
    excludeCol, includeCol, type = "object", debug = false
    ) => {
    if (debug) {
        debugger;
    }
    if (type === "object") {
        Object.keys(obj).map((key) => {
            if (debug) {
                debugger;
            }
            if (Array.isArray(obj[key]) || typeof obj[key] === "object") {
                obj[key] = changeObjValue(
                    obj[key], revertToLastState, newCurr, lastCurr, excludeCol, includeCol, typeof obj[key] === "object" ? "object" : "list", debug
                );
            } else {
                let newValue = null;
                if (!isNaN(parseInt(obj[key])) && (
                    includeCol.length > 0 ? includeCol.includes(key) : !excludeCol.includes(key)
                )
                ) {
                    newValue = revertToLastState ? parseFloat((obj[key] / lastCurr) * newCurr).toFixed(2) : parseFloat(obj[key] * newCurr).toFixed(2);
                }else{
                    newValue = obj[key]
                }
                delete obj[key]
                obj[key] = newValue
            }
            return key;
        });
    } else {
        if (debug) {
            debugger;
        }
        let data = obj;
        obj = [];
        data.map((dataObj) => {
            let newObj ;
            if (Array.isArray(dataObj) || typeof dataObj === "object") {
                newObj = changeObjValue(
                    dataObj, revertToLastState, newCurr, lastCurr, excludeCol, includeCol, typeof dataObj === "object" ? "object" : "list", debug // deepscan-disable-line
                );
            } else {
                if (!isNaN(parseInt(dataObj))) {
                    newObj = revertToLastState ? parseFloat((dataObj / lastCurr) * newCurr).toFixed(2) : parseFloat(dataObj * newCurr).toFixed;
                }
                else{
                    newObj = dataObj
                }
            }
            dataObj.push(newObj);
        });
    }
    return obj;
};

export const convertListCurrency = (
    data, revertToLastState = false, newCurr = 0, lastCurr = 0, excludeCol = [], includeCol = [], debug = false
) => {
    if (debug) {
        debugger;
    }
    if (Array.isArray(data)) {
        let newList = [];
        data.map((obj) => {
            newList.push(
                changeObjValue(
                    obj, revertToLastState, newCurr, lastCurr, excludeCol, includeCol, Array.isArray(obj) ? "list" : "object"
                )
            );
            return obj;
        });
        return newList;
    } else {
        let _data = changeObjValue(
            data, revertToLastState, newCurr, lastCurr, excludeCol, includeCol, "object", debug
        );
        console.log('currencydata', _data);
        return _data;
    }
};

export function extractKeyValue(obj, key) {
    if (Array.isArray(obj)) {
        return obj.map(item => extractKeyValue(item, key));
    } else if (typeof obj === 'object') {
        const keys = Object.keys(obj);
        if (keys.includes(key)) {
            return obj[key];
        } else {
            return Object.values(obj).map(item => extractKeyValue(item, key));
        }
    } else {
        return null;
    }
}

export function isKeyInObject(obj, key) {
    if (Array.isArray(obj)) {
        return obj.map(item => isKeyInObject(item, key));
    } else if (typeof obj === 'object') {
        const keys = Object.keys(obj);
        if (keys.includes(key)) {
            return true;
        } else {
            return Object.values(obj).some(item => isKeyInObject(item, key));
        }
    } else {
        return false;
    }
}


export const changeDataObjCurrencyUnit = (
    data = [],
    currencyFormat = '',
    excludeCol = [],
    includeCol = []
) => {
    const currencyFromatList = cloneDeep(CURRENCY_UNITS_OBJECT);

    const convertValue = (value, unit) => {
        currencyFromatList.map(curr => {
            if(String(curr.format) === String(unit)){
                value  = currencyFromatList.curr.conversionMethod(value)
                return value
            }
            return curr
        })
        return value;
    };

    const processObject = (obj) => {
        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key]) || typeof obj[key] === 'object') {
                obj[key] = changeDataObjCurrencyUnit(obj[key], currencyFormat, excludeCol, includeCol);
            } else if (!isNaN(parseInt(obj[key])) && (
                includeCol.length > 0 ? includeCol.includes(key) : !excludeCol.includes(key)
            )) {
                obj[key] = convertValue(obj[key], currencyFormat);
            }
        });
        return obj;
    };

    if (Array.isArray(data)) {
        return data.map(item => changeDataObjCurrencyUnit(item, currencyFormat, excludeCol, includeCol));
    } else if (typeof data === 'object') {
        return processObject(data);
    } else if (!isNaN(parseInt(data)) && (
        includeCol.length > 0 ? includeCol.includes(data) : !excludeCol.includes(data)
    )) {
        return convertValue(data, currencyFormat);
    }
    return data;
};
