import { DEFAULT_TABLE_PAGE_SIZE } from "../../utilities/constants";
import {
    SET_ADMIN_PANEL_ACTIVE_TAB_KEY,
    SET_APP_SCREENS_LIST_MAPPING,
    SET_APP_USER_SCOPE_LIST_MAPPING,
    SET_APP_USERS_TABLE_LIST_MAPPING,
    SET_APP_USERS_TABLE_CURRENT_PAGE,
    SET_APP_USERS_TABLE_PAGE_SIZE,
    SET_APP_USERS_TABLE_TOTAL_PAGE_COUNT,
    SET_APP_USERS_TABLE_FILTERS,
    SET_APP_TARGETS_TABLE_LIST_MAPPING,
    SET_APP_TARGETS_TABLE_CURRENT_PAGE,
    SET_APP_TARGETS_TABLE_PAGE_SIZE,
    SET_APP_TARGETS_TABLE_TOTAL_PAGE_COUNT,
    SET_APP_TARGETS_TABLE_FILTERS,
    SET_APP_USER_EMAILS_LIST_MAPPING,
    SET_APP_NPI_TABLE_LIST_MAPPING,
    SET_APP_NPT_TABLE_CURRENT_PAGE,
    SET_APP_NPI_TABLE_PAGE_SIZE,
    SET_APP_METRIC_KPI_TABLE_LIST_MAPPING,
    SET_APP_METRIC_KPI_TABLE_CURRENT_PAGE,
    SET_APP_METRIC_KPI_TABLE_PAGE_SIZE,
    SET_APP_METRIC_KPI_TABLE_TOTAL_PAGE_COUNT,
    SET_APP_METRIC_KPI_TABLE_FILTERS,
    SET_APP_COUNTRY_METRIC_KPI_LIST_MAPPING,
    SET_APP_NPI_TABLE_TOTAL_PAGE_COUNT
} from "../actions/admin-action"

const tabsInitialState = {
    data: [],
    hasNext: false,
    isLoading: false,
    currentPage: 1,
    totalRecordCount: 0,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    tableFilters: {}
};
const initialState = {
    applicationScreensList: { data: [], hasNext: false },
    applicationUserScopeList: { data: [], hasNext: false },
    countryBasedMetricKpiList: { data: [], hasNext: false },
    applicationUserEmailsList: {
        data: [],
        hasNext: false,
        isLoading: false
    },
    applicationUsersList: { ...tabsInitialState, tableFilters: { Email: '', UserCountryId: [] } },
    peakEventsTargetsList: { ...tabsInitialState, tableFilters: { Event: '', CountryId: [] } },
    appNewProductsList: tabsInitialState,
    appMetricKpisList: { ...tabsInitialState, tableFilters: { MetricName: '', CountryId: [] } },
    adminPanelActiveTabKey: 1,
}

const adminPanelReducer = (state = initialState, action) => {
    if (
        (action.type === SET_APP_USERS_TABLE_LIST_MAPPING) ||
        (action.type === SET_APP_USERS_TABLE_CURRENT_PAGE) ||
        (action.type === SET_APP_USERS_TABLE_PAGE_SIZE) ||
        (action.type === SET_APP_USERS_TABLE_TOTAL_PAGE_COUNT) ||
        (action.type === SET_APP_USERS_TABLE_FILTERS)
    ) {
        return { ...state, applicationUsersList: action.payload };
    } else if (
        (action.type === SET_APP_TARGETS_TABLE_LIST_MAPPING) ||
        (action.type === SET_APP_TARGETS_TABLE_CURRENT_PAGE) ||
        (action.type === SET_APP_TARGETS_TABLE_PAGE_SIZE) ||
        (action.type === SET_APP_TARGETS_TABLE_TOTAL_PAGE_COUNT) ||
        (action.type === SET_APP_TARGETS_TABLE_FILTERS)
    ) {
        return { ...state, peakEventsTargetsList: action.payload };
    } else if (
        (action.type === SET_APP_METRIC_KPI_TABLE_LIST_MAPPING) ||
        (action.type === SET_APP_METRIC_KPI_TABLE_CURRENT_PAGE) ||
        (action.type === SET_APP_METRIC_KPI_TABLE_PAGE_SIZE) ||
        (action.type === SET_APP_METRIC_KPI_TABLE_TOTAL_PAGE_COUNT) ||
        (action.type === SET_APP_METRIC_KPI_TABLE_FILTERS)
    ) {
        return { ...state, appMetricKpisList: action.payload };
    } else if (
        (action.type === SET_APP_NPI_TABLE_LIST_MAPPING) ||
        (action.type === SET_APP_NPT_TABLE_CURRENT_PAGE) ||
        (action.type === SET_APP_NPI_TABLE_PAGE_SIZE) ||
        (action.type === SET_APP_NPI_TABLE_TOTAL_PAGE_COUNT)
    ) {
        return { ...state, appNewProductsList: action.payload };
    } else if (
        (action.type === SET_ADMIN_PANEL_ACTIVE_TAB_KEY)
    ) {
        return { ...state, adminPanelActiveTabKey: action.payload };
    } else if (
        (action.type === SET_APP_SCREENS_LIST_MAPPING)
    ) {
        return { ...state, applicationScreensList: action.payload };
    } else if (
        (action.type === SET_APP_COUNTRY_METRIC_KPI_LIST_MAPPING)
    ) {
        return { ...state, countryBasedMetricKpiList: action.payload };
    } else if (
        (action.type === SET_APP_USER_EMAILS_LIST_MAPPING)
    ) {
        return { ...state, applicationUserEmailsList: action.payload };
    } else if (
        (action.type === SET_APP_USER_SCOPE_LIST_MAPPING)
    ) {
        return { ...state, applicationUserScopeList: action.payload };
    } else {
        return { ...state };
    }
}
export default adminPanelReducer;