import { DEFAULT_TABLE_PAGE_SIZE } from '../../utilities/constants';
import {
    SET_TARGETS_PLAN_SUMMARY_DATA,
    SET_TARGETS_PLAN_SUMMARY_API_REFRESH,
    SET_TARGETS_SUMMARY_DATA_TABLE_CURRENT_PAGE,
    SET_TARGETS_SUMMARY_DATA_TABLE_PAGE_SIZE,
    SET_TARGETS_SUMMARY_DATA_TOTAL_RECORD_COUNT,
    SET_TARGETS_SUMMARY_DROPDOWN_SELECTED_FILTERS,
    SET_TARGETS_SUMMARY_DATA_TABLE_DEFAULT_FILTERS,
    SET_TARGETS_PLAN_SUMMARY_DATA_CLONE,
    SET_TARGETS_SUMMARY_TABLE_DATA_LOADING
} from '../actions/summary-targets-action';

const initialState = {
    defaultFilters: { CountryId : [], EventId: 0, Year: '', DateDiff: 0, CategoryId:[], Sku: null, Asin: null },
    dropdownAppliedFilters: { CountryId : [], EventId: 0, Year: '',  Sku: null, Asin: null },
    targetsSummaryData: { data: [], isLoading: false },
    targetsSummaryDataClone: { displayData: [], isLoading: false },
    totalRecordCount: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    currentPage: 1,
    error: null,
    isApiRefresh: false,
    isTableDataLoading: false
}

export const targetsSummaryReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_TARGETS_SUMMARY_DATA_TABLE_DEFAULT_FILTERS:
            return { ...state, defaultFilters: action.payload };

        case SET_TARGETS_SUMMARY_DROPDOWN_SELECTED_FILTERS:
            return { ...state, dropdownAppliedFilters: action.payload };

        case SET_TARGETS_PLAN_SUMMARY_API_REFRESH:
            return { ...state, isApiRefresh: action.payload }

        case SET_TARGETS_PLAN_SUMMARY_DATA:
            return { ...state, targetsSummaryData: action.payload }

        case SET_TARGETS_PLAN_SUMMARY_DATA_CLONE:
            return { ...state, targetsSummaryDataClone: action.payload }

        case SET_TARGETS_SUMMARY_DATA_TABLE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case SET_TARGETS_SUMMARY_DATA_TABLE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case SET_TARGETS_SUMMARY_DATA_TOTAL_RECORD_COUNT:
            return { ...state, totalRecordCount: action.payload };

        case SET_TARGETS_SUMMARY_TABLE_DATA_LOADING:
            return { ...state, isTableDataLoading: action.payload };

        default:
            return state;
    }
}
export default targetsSummaryReducer;
