import { cloneDeep } from "lodash";

export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_DISPLAY_DETAILS = 'SET_DISPLAY_DETAILS';
export const SET_DISPLAY_CATEGORIES = 'SET_DISPLAY_CATEGORIES';

export const setUserDetails = (data) => ({
    type:SET_USER_DETAILS, payload: cloneDeep(data)
})


export const setDisplayDetails = (username, countryCode) => {
    const obj = {}
    obj['UserName'] = username
    obj['CountryCode'] = countryCode
    return {type:SET_DISPLAY_DETAILS, payload:obj}
}


export const setDisplayCategories = (categories = []) => ({
    type:SET_DISPLAY_CATEGORIES, payload:categories
})
