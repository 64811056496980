import {
    SET_DISPLAY_CATEGORIES, SET_DISPLAY_DETAILS,
    SET_USER_DETAILS
} from "../actions/users"

const initialState = {
    userDetails: {
        data: [],
        email: '',
        userName: "",
        userId: "",
        accessCountries: [],
        targetCountries: [],
        dealplannerCountries: [],
        monthlyplannerCountries: [],
        weeklyplannerCountries: [],
        pricesCountries:[],
        hasAdminAccess:false
    },
    displayDetails: { UserName: '', CountryCode: '' },
    userCategories: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return { ...state, userDetails: action.payload }

        case SET_DISPLAY_DETAILS:
            return { ...state, displayDetails: action.payload }

        case SET_DISPLAY_CATEGORIES:
            return {
                ...state,
                userCategories: action.payload
            }

        default:
            return { ...state }
    }
}
export default userReducer;
